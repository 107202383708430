import React, { useState, useEffect } from 'react'
import MzTable from '../../common/MzTable'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { useNavigate } from 'react-router-dom'
import { BreadCrumb } from 'primereact/breadcrumb'
import MzDialog from '../../common/MzDialog'
import AddEditSevenEnquiryScreen from '../../containers/sevenEnquiryScreen/addEditSevenEnquiryScreen/index'
const SevenEnquiryComponent = (props) => {
  const {
    EnquirySevenTableData,
    fetchSevenEnquiryList,
    isPageLevelError,
    isLoading,
    handleOnReadRecord,
    visibleSevenEnquiry,
    setvisibleSevenEnquiry,
    sevenEnquiryList
  } = props.EnquirySevenProps

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const payload = {
        pageNumber: 0,
        pageSize: 10
      }
      fetchSevenEnquiryList(payload)
    }, 300)
    return () => clearTimeout(delayDebounceFn)
  }, [fetchSevenEnquiryList])
  const [paginationInfo, setPaginationInfo] = useState({
    pageSize: 10,
    pageNumber: 0,
    totalRecords: 0,
    totalPages: 0
  })
  const loadLazyData = (event) => {
    const { pageNumber, pageSize } = event
    setPaginationInfo((prev) => ({
      ...prev,
      pageNumber,
      pageSize
    }))
    fetchSevenEnquiryList({ pageNumber, pageSize })
  }
  const history = useNavigate()

  const shouldRenderFullPageError = () => isPageLevelError
  const shouldRenderUserTransactionList = () =>
    EnquirySevenTableData?.tableData?.columns?.length > 0
  const shouldRenderNotFoundView = () =>
    !shouldRenderFullPageError && !shouldRenderUserTransactionList

  const renderPageHeaderSection = () => {
    const breadCrumbItems = [
      { label: 'Enquiry', command: () => history(ROUTE_PATH.LINK.HOME) }
    ]
    const home = {
      icon: 'pi pi-home',
      command: () => history(ROUTE_PATH.DASHBOARD.HOME)
    }

    return (
      <div className="flex justify-content-between align-items-center border-1 border-200 border-round bg-white">
        <BreadCrumb model={breadCrumbItems} home={home} className="border-0" />
      </div>
    )
  }

  const tableProps = {
    // value: sevenEnquiryList?.content ?? [],
    value:
      sevenEnquiryList?.content?.map((item) => ({
        ...item,
        enquiryType:
          item.enquiryType === 'BOTH'
            ? '7/12 & Index2'
            : item.enquiryType === 'INDEX1'
              ? 'Index2'
              : '7/12'
      })) ?? [],
    loading: isLoading,
    paginationInfo: {
      ...paginationInfo,
      totalRecords: sevenEnquiryList?.totalElements ?? 0
    },
    screenPermission: EnquirySevenTableData?.tableData?.screenPermission ?? {},
    columns: EnquirySevenTableData?.tableData?.columns,
    onReadRecord: handleOnReadRecord,
    loadLazyData,
    emptyMessage: 'No Enquiry Record Found',
    sortField: null,
    showMoreAction: false
  }

  return (
    <div>
      {renderPageHeaderSection()}
      {shouldRenderUserTransactionList() && (
        <div className="border-1 border-200 border-round mt-3 p-2 bg-white">
          <MzTable {...tableProps} />
        </div>
      )}
      {shouldRenderNotFoundView() && <div>No record to show</div>}
      {
        <MzDialog
          onHide={() => setvisibleSevenEnquiry(false)}
          visible={visibleSevenEnquiry}
          children={
            <AddEditSevenEnquiryScreen
              setvisibleSevenEnquiry={setvisibleSevenEnquiry}
            />
          }
        ></MzDialog>
      }
    </div>
  )
}

export default SevenEnquiryComponent
